// client/src/pages/ManageClients.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import Loader from '../components/Loader';

const ManageClients = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newClient, setNewClient] = useState({ name: '', description: '' });
    const [showModal, setShowModal] = useState(false);
    const [editClient, setEditClient] = useState(null);
    const [assignedApps, setAssignedApps] = useState([]);
    const [newLicense, setNewLicense] = useState({ appId: '', key: '', expirationDate: '' });
    const [expandedApps, setExpandedApps] = useState({});
    const [expandedLicenses, setExpandedLicenses] = useState({});
    const [licenseUsageLogs, setLicenseUsageLogs] = useState({});
    const [availableApps, setAvailableApps] = useState([]);
    const [filteredApps, setFilteredApps] = useState([]);

    useEffect(() => {
        fetchClients();
        fetchAvailableApps();
    }, []);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    };

    const fetchClients = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/clients`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setClients(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching clients:', error);
            setError('Error fetching clients. Please try again.');
            setLoading(false);
        }
    };

    const fetchAvailableApps = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/apps`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setAvailableApps(response.data);
        } catch (error) {
            console.error('Error fetching available apps:', error);
        }
    };

    const handleAppIdChange = (e) => {
        const value = e.target.value;
        setNewLicense({ ...newLicense, appId: value });

        if (value) {
            const filtered = availableApps.filter(app =>
                app.name.toLowerCase().includes(value.toLowerCase()) ||
                app._id.includes(value)
            );
            setFilteredApps(filtered);
        } else {
            setFilteredApps([]);
        }
    };

    const selectApp = (app) => {
        setNewLicense({ ...newLicense, appId: app._id });
        setFilteredApps([]);
    };

    const handleAddClient = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/clients`, newClient, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setClients([...clients, response.data]);
            setNewClient({ name: '', description: '' });
            setShowModal(false);
        } catch (error) {
            setError('Error adding client');
        }
    };

    const handleEditClient = async (client) => {
        const token = localStorage.getItem('token');
        setEditClient(client);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/clients/${client._id}/apps`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setAssignedApps(response.data);
        } catch (error) {
            setError('Error fetching assigned apps');
        }
    };

    const generateLicenseKey = () => {
        const key = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        setNewLicense({ ...newLicense, key });
    };

    const handleAssignApp = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/clients/${editClient._id}/assign-app`, newLicense, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setAssignedApps([...assignedApps, response.data.app]);
            setNewLicense({ appId: '', key: '', expirationDate: '' });
        } catch (error) {
            setError('Error assigning app');
        }
    };

    const revokeLicense = async (appId, licenseKey) => {
        const token = localStorage.getItem('token');
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/apps/${appId}/license/${licenseKey}/revoke`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            // Refresh assigned apps data
            handleEditClient(editClient);
        } catch (error) {
            console.error('Error revoking license:', error);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        alert("License key copied to clipboard!");
    };

    const handleSaveEditClient = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/clients/${editClient._id}`, editClient, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setClients(clients.map(client => (client._id === editClient._id ? response.data : client)));
            setEditClient(null);
        } catch (error) {
            setError('Error editing client');
        }
    };

    const handleDeleteClient = async (clientId) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/clients/${clientId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setClients(clients.filter(client => client._id !== clientId));
        } catch (error) {
            setError('Error deleting client');
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setNewClient({ name: '', description: '' });
    };

    const changeExpirationDate = async (appId, licenseKey, newExpirationDate) => {
        const token = localStorage.getItem('token');
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/apps/${appId}/license/${licenseKey}`,
                { expirationDate: newExpirationDate },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            // Refresh assigned apps data
            handleEditClient(editClient);
        } catch (error) {
            setError('Error changing expiration date');
        }
    };

    const toggleAppExpansion = (appId) => {
        setExpandedApps(prev => ({
            ...prev,
            [appId]: !prev[appId]
        }));
    };

    const toggleLicenseDetails = (appId, licenseKey) => {
        setExpandedLicenses(prev => ({
            ...prev,
            [appId]: {
                ...prev[appId],
                [licenseKey]: !prev[appId]?.[licenseKey]
            }
        }));
        if (!licenseUsageLogs[`${appId}-${licenseKey}`]) {
            fetchLicenseUsageLogs(appId, licenseKey);
        }
    };

    const fetchLicenseUsageLogs = async (appId, licenseKey) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/apps/${appId}/license/${licenseKey}/logs`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setLicenseUsageLogs(prev => ({
                ...prev,
                [`${appId}-${licenseKey}`]: response.data
            }));
        } catch (error) {
            console.error('Error fetching license usage logs:', error);
        }
    };

    const unrevokeLicense = async (appId, licenseKey) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/api/apps/${appId}/license/${licenseKey}/unrevoke`,
                {}, // empty body
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.status === 200) {
                // Refresh assigned apps data
                handleEditClient(editClient);
            } else {
                setError(`Failed to unrevoke license: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error unrevoking license:', error);
            setError(error.response?.data?.message || 'Failed to unrevoke license');
        }
    };

    const extendExpirationDate = async (appId, licenseKey, extensionDays) => {
        const token = localStorage.getItem('token');
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/apps/${appId}/license/${licenseKey}/extend`,
                { extensionDays },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            // Refresh assigned apps data
            handleEditClient(editClient);
        } catch (error) {
            console.error('Error extending license expiration:', error);
            setError('Failed to extend license expiration');
        }
    };


    return (
        <div className="min-h-screen bg-gray-100 p-6">
            <h1 className="text-3xl font-semibold text-gray-800 mb-6">Manage Clients</h1>
            {loading ? (
                <Loader loading={loading} />
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <>
                    <div className="mb-6">
                        <button
                            onClick={() => setShowModal(true)}
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105"
                        >
                            Add Client
                        </button>
                    </div>

                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Existing Clients</h2>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <table className="min-w-full">
                            <thead className="bg-gray-50">
                            <tr>
                                <th className="py-2 px-4 text-left">Name</th>
                                <th className="py-2 px-4 text-left">Assigned Apps</th>
                                <th className="py-2 px-4 text-right">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {clients.map((client) => (
                                <tr key={client._id} className="border-b">
                                    <td className="py-2 px-4">{client.name}</td>
                                    <td className="py-2 px-4">{client.assignedApps?.slice(0, 2).map(app => app.name).join(', ')}</td>
                                    <td className="py-2 px-4 text-right">
                                        <button
                                            onClick={() => handleEditClient(client)}
                                            className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:scale-105 mr-2"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => handleDeleteClient(client._id)}
                                            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300 ease-in-out transform hover:scale-105"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            <CSSTransition
                in={showModal}
                timeout={300}
                classNames="modal"
                unmountOnExit
            >
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg transform transition-all scale-95 opacity-0 modal-enter-active scale-100 opacity-100 w-3/4 max-w-lg">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Add New Client</h2>
                        <input
                            type="text"
                            placeholder="Client Name"
                            value={newClient.name}
                            onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
                            className="block w-full p-2 mb-2 border rounded"
                        />
                        <textarea
                            placeholder="Client Description"
                            value={newClient.description}
                            onChange={(e) => setNewClient({ ...newClient, description: e.target.value })}
                            className="block w-full p-2 mb-2 border rounded"
                        />
                        <button
                            onClick={handleAddClient}
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105 mr-2"
                        >
                            Add Client
                        </button>
                        <button
                            onClick={closeModal}
                            className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </CSSTransition>
            {editClient && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto">
                    <div className="bg-white p-6 rounded-lg shadow-md w-3/4 max-h-[90vh] overflow-y-auto">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Edit Client: {editClient.name}</h2>
                        <input
                            type="text"
                            placeholder="Client Name"
                            value={editClient.name}
                            onChange={(e) => setEditClient({...editClient, name: e.target.value})}
                            className="block w-full p-2 mb-4 border rounded"
                        />

                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Assigned Apps:</h3>
                        {assignedApps.map(app => (
                            <div key={app._id} className="mb-4 border-b pb-4">
                                <div
                                    className="flex items-center cursor-pointer bg-gray-100 p-2 rounded"
                                    onClick={() => toggleAppExpansion(app._id)}
                                >
                                    <span className="mr-2">
                                        {expandedApps[app._id] ? '▼' : '▶'}
                                    </span>
                                    <h4 className="text-lg font-medium">{app.name}</h4>
                                </div>

                                {expandedApps[app._id] && app.licenses.map(license => (
                                    <div key={license.key} className="ml-4 mt-2 p-2 bg-gray-50 rounded">
                                        <div
                                            className="flex items-center cursor-pointer text-blue-600 hover:text-blue-800"
                                            onClick={() => toggleLicenseDetails(app._id, license.key)}
                                        >
                                            <span className="mr-2">
                                                {expandedLicenses[app._id]?.[license.key] ? '▼' : '▶'}
                                            </span>
                                            <span className="font-medium">License Key: {license.key}</span>
                                        </div>
                                        <div className="ml-6 text-sm text-gray-600">
                                            <p>Expiration: {new Date(license.expirationDate).toLocaleDateString()}</p>
                                            <p>Usage Count: {license.usageCount || 0}</p>
                                        </div>

                                        {expandedLicenses[app._id]?.[license.key] && (
                                            <div className="ml-6 mt-2">
                                                <p>Registered: {new Date(license.registeredDate).toLocaleDateString()}</p>
                                                <p>Status: {license.active ? 'Active' : 'Inactive'}</p>
                                                <div className="mt-2">
                                                    <input
                                                        type="date"
                                                        value={license.expirationDate ? new Date(license.expirationDate).toISOString().split('T')[0] : ''}
                                                        onChange={(e) => changeExpirationDate(app._id, license.key, e.target.value)}
                                                        className="p-1 border rounded mr-2"
                                                    />
                                                    <button
                                                        onClick={() => license.active ? revokeLicense(app._id, license.key) : unrevokeLicense(app._id, license.key)}
                                                        className={`px-2 py-1 text-white rounded-md transition duration-300 ease-in-out transform hover:scale-105 mr-2 ${
                                                            license.active ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
                                                        }`}
                                                    >
                                                        {license.active ? 'Revoke License' : 'Unrevoke License'}
                                                    </button>
                                                    <button
                                                        onClick={() => copyToClipboard(license.key)}
                                                        className="px-2 py-1 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-300 ease-in-out transform hover:scale-105 mr-2"
                                                    >
                                                        Copy License Key
                                                    </button>
                                                    <div className="mt-2">
                                                        <input
                                                            type="number"
                                                            placeholder="Days to extend"
                                                            className="p-1 border rounded mr-2"
                                                            id={`extend-days-${license.key}`}
                                                        />
                                                        <button
                                                            onClick={() => {
                                                                const days = document.getElementById(`extend-days-${license.key}`).value;
                                                                extendExpirationDate(app._id, license.key, days);
                                                            }}
                                                            className="px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105"
                                                        >
                                                            Extend Expiration
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <h5 className="font-medium">Usage Logs:</h5>
                                                    <ul className="list-disc list-inside max-h-40 overflow-y-auto">
                                                        {licenseUsageLogs[`${app._id}-${license.key}`]?.map((log, index) => (
                                                            <li key={index} className="text-sm">
                                                                {new Date(log.timestamp).toLocaleString()}: {log.action}
                                                            </li>
                                                        )) || <li>No usage logs available</li>}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}

                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Assign New App:</h3>
                        <div className="mb-4 relative">
                            <input
                                type="text"
                                placeholder="Start typing app name or ID"
                                value={newLicense.appId}
                                onChange={handleAppIdChange}
                                className="block w-full p-2 mb-2 border rounded"
                            />
                            {filteredApps.length > 0 && (
                                <ul className="absolute z-10 bg-white border border-gray-300 w-full max-h-40 overflow-y-auto">
                                    {filteredApps.map(app => (
                                        <li
                                            key={app._id}
                                            onClick={() => selectApp(app)}
                                            className="p-2 hover:bg-gray-100 cursor-pointer"
                                        >
                                            {app.name} ({app._id})
                                        </li>
                                    ))}
                                </ul>
                            )}
                            <input
                                type="text"
                                placeholder="License Key"
                                value={newLicense.key}
                                onChange={(e) => setNewLicense({...newLicense, key: e.target.value})}
                                className="block w-full p-2 mb-2 border rounded"
                            />
                            <button
                                onClick={generateLicenseKey}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105 mr-2"
                            >
                                Generate License Key
                            </button>
                            <input
                                type="datetime-local"
                                value={newLicense.expirationDate}
                                onChange={(e) => setNewLicense({...newLicense, expirationDate: e.target.value})}
                                className="block w-full p-2 mb-2 border rounded"
                            />
                            <button
                                onClick={handleAssignApp}
                                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300 ease-in-out transform hover:scale-105"
                            >
                                Assign App
                            </button>
                        </div>
                        <div className="mt-4">
                            <button
                                onClick={handleSaveEditClient}
                                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105 mr-2"
                            >
                                Save
                            </button>
                            <button
                                onClick={() => setEditClient(null)}
                                className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageClients;
