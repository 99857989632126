// client/src/pages/Dashboard.js

import React, { useEffect, useState } from 'react';
import { animated } from '@react-spring/web';
import axios from 'axios';

const Dashboard = () => {
    const [data, setData] = useState({
        totalUsers: 0,
        totalApps: 0,
        totalLicenses: 0,
        totalValidationsToday: 0,
        totalValidationsThisWeek: 0,
        totalValidationsThisMonth: 0,
    });

    const [fadeIn, setFadeIn] = useState({ opacity: 0 });

    useEffect(() => {
        setFadeIn({ opacity: 1 });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard-data`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchData();

        const interval = setInterval(fetchData, 60000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 flex">
            <div className="flex-1 p-6">
                <header>
                    <h1 className="text-3xl font-semibold text-gray-800">Dashboard</h1>
                </header>

                <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <animated.div style={fadeIn} className="bg-white p-6 rounded-lg shadow-md transition duration-500 hover:shadow-xl transform hover:scale-105">
                        <h2 className="text-xl font-semibold text-gray-800">Total Users</h2>
                        <p className="mt-2 text-gray-600">{data.totalUsers}</p>
                    </animated.div>
                    <animated.div style={fadeIn} className="bg-white p-6 rounded-lg shadow-md transition duration-500 hover:shadow-xl transform hover:scale-105">
                        <h2 className="text-xl font-semibold text-gray-800">Total Apps</h2>
                        <p className="mt-2 text-gray-600">{data.totalApps}</p>
                    </animated.div>
                    <animated.div style={fadeIn} className="bg-white p-6 rounded-lg shadow-md transition duration-500 hover:shadow-xl transform hover:scale-105">
                        <h2 className="text-xl font-semibold text-gray-800">Total Licenses Issued</h2>
                        <p className="mt-2 text-gray-600">{data.totalLicenses}</p>
                    </animated.div>
                    <animated.div style={fadeIn} className="bg-white p-6 rounded-lg shadow-md transition duration-500 hover:shadow-xl transform hover:scale-105">
                        <h2 className="text-xl font-semibold text-gray-800">Validations Today</h2>
                        <p className="mt-2 text-gray-600">{data.totalValidationsToday}</p>
                    </animated.div>
                    <animated.div style={fadeIn} className="bg-white p-6 rounded-lg shadow-md transition duration-500 hover:shadow-xl transform hover:scale-105">
                        <h2 className="text-xl font-semibold text-gray-800">Validations This Week</h2>
                        <p className="mt-2 text-gray-600">{data.totalValidationsThisWeek}</p>
                    </animated.div>
                    <animated.div style={fadeIn} className="bg-white p-6 rounded-lg shadow-md transition duration-500 hover:shadow-xl transform hover:scale-105">
                        <h2 className="text-xl font-semibold text-gray-800">Validations This Month</h2>
                        <p className="mt-2 text-gray-600">{data.totalValidationsThisMonth}</p>
                    </animated.div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
