// client/src/pages/ManageApps.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import Loader from '../components/Loader';

const ManageApps = () => {
    const [apps, setApps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newApp, setNewApp] = useState({ name: '', description: '' });
    const [showModal, setShowModal] = useState(false);
    const [selectedApp, setSelectedApp] = useState(null);
    const [assignedClients, setAssignedClients] = useState([]);
    const [unassignedClients, setUnassignedClients] = useState([]);
    const [showInstructions, setShowInstructions] = useState({});
    const [copiedStates, setCopiedStates] = useState({});

    useEffect(() => {
        const fetchApps = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/apps`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setApps(response.data);
                setLoading(false);
            } catch (error) {
                setError('Error fetching apps');
                setLoading(false);
            }
        };

        fetchApps();
    }, []);

    const handleAppSelection = async (app) => {
        setSelectedApp(app);
        const token = localStorage.getItem('token');
        try {
            const assignedResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/apps/${app._id}/clients`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const uniqueAssignedClients = [...new Set(assignedResponse.data.map(client => client._id))]
                .map(id => assignedResponse.data.find(client => client._id === id));

            setAssignedClients(uniqueAssignedClients);

            const clientsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/clients`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const unassigned = clientsResponse.data.filter(client =>
                !uniqueAssignedClients.some(assignedClient => assignedClient._id === client._id)
            );
            setUnassignedClients(unassigned);

            setShowModal(true);
        } catch (error) {
            setError('Error fetching clients');
        }
    };

    const handleAddApp = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/apps`, newApp, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setApps([...apps, response.data]);
            setNewApp({ name: '', description: '' });
            setShowModal(false);
        } catch (error) {
            setError('Error adding app');
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedApp(null);
    };

    const handleAssignClient = async (clientId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/apps/${selectedApp._id}/clients`, {
                clientIds: [clientId],
                action: 'assign'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Update the app state with the new licenses
            setApps(apps.map(app =>
                app._id === selectedApp._id ? response.data.app : app
            ));

            // Move client from unassigned to assigned
            const client = unassignedClients.find(c => c._id === clientId);
            setAssignedClients([...assignedClients, client]);
            setUnassignedClients(unassignedClients.filter(c => c._id !== clientId));

            // Optionally, you can display a success message to the user
            console.log('Client assigned and license generated successfully');
        } catch (error) {
            setError('Error assigning client');
        }
    };

    const handleUnassignClient = async (clientId) => {
        const token = localStorage.getItem('token');
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/apps/${selectedApp._id}/clients`, {
                clientIds: [clientId],
                action: 'unassign'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Move client from assigned to unassigned
            const client = assignedClients.find(c => c._id === clientId);
            setUnassignedClients([...unassignedClients, client]);
            setAssignedClients(assignedClients.filter(c => c._id !== clientId));
        } catch (error) {
            setError('Error unassigning client');
        }
    };

    const generateValidationEndpoint = (appId) => {
        return `${process.env.REACT_APP_API_URL}/api/apps/${appId}/license/{licenseKey}/validate`;
    };

    const toggleInstructions = (appId) => {
        setShowInstructions(prev => ({
            ...prev,
            [appId]: !prev[appId]
        }));
    };

    const ValidationInstructions = ({ appId }) => {
        const [copied, setCopied] = useState(false);
        const endpointUrl = generateValidationEndpoint(appId);

        const copyToClipboard = () => {
            navigator.clipboard.writeText(endpointUrl).then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        };

        return (
            <div className="mt-2">
                <button
                    onClick={() => toggleInstructions(appId)}
                    className="flex items-center text-blue-600 hover:text-blue-800 mb-2"
                >
                    <span className="mr-2">{showInstructions[appId] ? '▼' : '▶'}</span>
                    {showInstructions[appId] ? 'Hide' : 'Show'} Validation Instructions
                </button>
                <CSSTransition
                    in={showInstructions[appId]}
                    timeout={300}
                    classNames="instructions"
                    unmountOnExit
                >
                    <div className="p-4 bg-gray-100 rounded-lg instructions-enter instructions-enter-active">
                        <h4 className="text-lg font-semibold mb-2">Validation Endpoint:</h4>
                        <div className="flex items-center bg-gray-200 p-2 rounded">
                            <code className="flex-grow">{endpointUrl}</code>
                            <button
                                onClick={copyToClipboard}
                                className="ml-2 p-1 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                                title={copied ? "Copied!" : "Copy to clipboard"}
                            >
                                {copied ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                                        <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" />
                                        <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z" />
                                    </svg>
                                )}
                            </button>
                        </div>
                        <h4 className="text-lg font-semibold mt-4 mb-2">Usage Instructions:</h4>
                        <ol className="list-decimal list-inside">
                            <li>Replace <code>{'{licenseKey}'}</code> with the actual license key.</li>
                            <li>Send a GET request to this endpoint to validate a license.</li>
                            <li>The response will indicate if the license is valid.</li>
                        </ol>
                        <h4 className="text-lg font-semibold mt-4 mb-2">Example Response:</h4>
                        <pre className="bg-gray-200 p-2 rounded">
{`{
    "isValid": true,
    "message": "License valid"
}`}
                    </pre>
                        <p className="mt-2">
                            Implement this validation in your app to ensure users have a valid license.
                        </p>
                    </div>
                </CSSTransition>
            </div>
        );
    };
    return (
        <div className="min-h-screen bg-gray-100 p-6">
            <h1 className="text-3xl font-semibold text-gray-800 mb-6">Manage Apps</h1>
            {loading ? (
                <Loader loading={loading} />
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <>
                    <div className="mb-6">
                        <button
                            onClick={() => setShowModal(true)}
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105"
                        >
                            Add App
                        </button>
                    </div>

                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Existing Apps</h2>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <table className="min-w-full">
                            <thead className="bg-gray-50">
                            <tr>
                                <th className="py-2 px-4 text-left">Name</th>
                                <th className="py-2 px-4 text-left">Description</th>
                                <th className="py-2 px-4 text-right">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {apps.map((app) => (
                                <tr key={app._id} className="border-b">
                                    <td className="py-2 px-4">{app.name}</td>
                                    <td className="py-2 px-4">{app.description}</td>
                                    <td className="py-2 px-4 text-right">
                                        <button
                                            onClick={() => handleAppSelection(app)}
                                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105 mr-2"
                                        >
                                            Manage Clients
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <CSSTransition
                        in={showModal}
                        timeout={300}
                        classNames="modal"
                        unmountOnExit
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto">
                            <div className="bg-white p-6 rounded-lg shadow-lg transform transition-all scale-95 opacity-0 modal-enter-active scale-100 opacity-100 w-3/4 max-w-4xl max-h-[90vh] overflow-y-auto">
                                {selectedApp ? (
                                    <>
                                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Manage {selectedApp?.name}</h2>
                                        <div className="mb-6">
                                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Validation Information</h3>
                                            <ValidationInstructions appId={selectedApp._id} />
                                        </div>
                                        <h3 className="text-xl font-semibold text-gray-800 mb-2">Manage Clients</h3>
                                        <div className="flex">
                                            <div className="w-1/2">
                                                <h4 className="text-lg font-semibold text-gray-800 mb-2">Assigned Clients</h4>
                                                <ul className="bg-white shadow-md rounded-lg overflow-hidden">
                                                    {assignedClients.map(client => (
                                                        <li key={client._id} className="p-2 border-b flex justify-between items-center">
                                                            {client.name}
                                                            <button
                                                                onClick={() => handleUnassignClient(client._id)}
                                                                className="text-red-500 hover:text-red-700 transition duration-300 ease-in-out"
                                                            >
                                                                Unassign
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="w-1/2 pl-4">
                                                <h4 className="text-lg font-semibold text-gray-800 mb-2">Unassigned Clients</h4>
                                                <ul className="bg-white shadow-md rounded-lg overflow-hidden">
                                                    {unassignedClients.map(client => (
                                                        <li key={client._id} className="p-2 border-b flex justify-between items-center">
                                                            {client.name}
                                                            <button
                                                                onClick={() => handleAssignClient(client._id)}
                                                                className="text-green-500 hover:text-green-700 transition duration-300 ease-in-out"
                                                            >
                                                                Assign
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <button
                                            onClick={closeModal}
                                            className="mt-4 px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105"
                                        >
                                            Close
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Add New App</h2>
                                        <input
                                            type="text"
                                            placeholder="App Name"
                                            value={newApp.name}
                                            onChange={(e) => setNewApp({ ...newApp, name: e.target.value })}
                                            className="block w-full p-2 mb-2 border rounded"
                                        />
                                        <textarea
                                            placeholder="App Description"
                                            value={newApp.description}
                                            onChange={(e) => setNewApp({ ...newApp, description: e.target.value })}
                                            className="block w-full p-2 mb-2 border rounded"
                                        />
                                        <button
                                            onClick={handleAddApp}
                                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105 mr-2"
                                        >
                                            Add App
                                        </button>
                                        <button
                                            onClick={closeModal}
                                            className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105"
                                        >
                                            Cancel
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </CSSTransition>
                </>
            )}
        </div>
    );
};

export default ManageApps;
