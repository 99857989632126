// client/src/components/Loader.js

import React from 'react';
import { ClipLoader } from 'react-spinners';

const Loader = ({ loading }) => {
    return (
        <div className="flex justify-center items-center min-h-screen">
            <ClipLoader color="#3FA9F5" loading={loading} size={150} />
        </div>
    );
};

export default Loader;
