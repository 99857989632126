// client/src/App.js

import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate, Link, useNavigate } from 'react-router-dom';
import AuthContext from './context/AuthContext';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ManageApps from './pages/ManageApps';
import ManageClients from './pages/ManageClients';

const App = () => {
    const { auth, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const checkTokenExpiration = () => {
            const token = localStorage.getItem('token');
            if (token) {
                const tokenData = JSON.parse(atob(token.split('.')[1]));
                if (tokenData.exp * 1000 < Date.now()) {
                    logout();
                    navigate('/login');
                }
            }
        };

        const interval = setInterval(checkTokenExpiration, 60000); // Check every minute
        checkTokenExpiration(); // Check immediately

        return () => clearInterval(interval);
    }, [logout, navigate]);

    if (!auth.token) {
        return (
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        );
    }

    return (
        <div className="flex min-h-screen bg-gray-100">
            <aside className="w-64 bg-white shadow-md">
                <div className="p-6">
                    <h2 className="text-2xl font-bold text-gray-800">Admin Dashboard</h2>
                </div>
                <nav className="mt-10">
                    <Link to="/dashboard" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-600 hover:text-white">
                        Dashboard
                    </Link>
                    <Link to="/apps" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-600 hover:text-white">
                        Manage Apps
                    </Link>
                    <Link to="/clients" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-600 hover:text-white">
                        Manage Clients
                    </Link>
                    <Link to="/settings" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-indigo-600 hover:text-white">
                        Settings
                    </Link>
                    <button onClick={logout} className="block w-full text-left py-2.5 px-4 mt-4 bg-red-600 text-white rounded transition duration-200 hover:bg-red-700">
                        Logout
                    </button>
                </nav>
            </aside>
            <div className="flex-1">
                <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/apps" element={<ManageApps />} />
                    <Route path="/clients" element={<ManageClients />} />
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                </Routes>
            </div>
        </div>
    );
};

export default App;